const generateBackSlash = (urlString) => {
  if (urlString[0] === '/') return '';
  return '/';
};

const getAbsoluteURL = (siteURL = '', apiURL = '') => {
  const SITE_URL = process.env.GATSBY_SITE_URL;
  const API_URL = process.env.GATSBY_API_URL;

  const hasNoURL = !siteURL && !apiURL;
  const isURLNotAString =
    typeof siteURL !== 'string' && typeof apiURL !== 'string';
  const isURLInvalid = hasNoURL || isURLNotAString;
  if (isURLInvalid) return '';

  const hasNoSiteURL = !siteURL;
  if (hasNoSiteURL) return `${API_URL}${generateBackSlash(apiURL)}${apiURL}`;

  return `${SITE_URL}${generateBackSlash(siteURL)}${siteURL}`;
};

export default getAbsoluteURL;
