import React from 'react';
import { graphql } from 'gatsby';
import Image from '@components/Image';
import MarkdownViewer from '@components/MarkdownViewer';
import isEmpty from 'lodash/isEmpty';
import Button from 'antd/lib/button';
import SEO from '@components/SEO';
import Layout from '@components/Layout/LayoutTemplates/CareerDevelopmentLayout';
import CardCarousel from '@components/CardCarousel';
import SocialSharing from '@components/SocialSharing';
import formatBytes from '@helpers/formatBytes';
import AuthorPagePreview from '@components/AuthorPagePreview';
import SpriteIconViewer from '@components/Icons/SpriteIconViewer';

import DesktopAds from '@components/ContentViewer/Advertisement/DesktopAds';
import MobileAds from '@components/ContentViewer/Advertisement/MobileAds';
import getAbsoluteURL from '@helpers/methods/getAbsoluteURL';
import selectContentImage from '@helpers/methods/modifyContents/utils/selectContentImage';
import getResourceCardsProps from '@helpers/methods/modifyContents/getResourceCardsProps';

const Resources = ({ data, location }) => {
  const previewAuthor = data?.strapiAuthorPages;

  let otherResources = data?.allStrapiResources?.nodes;
  otherResources = getResourceCardsProps(data?.allStrapiResources?.nodes);

  let resource = data?.strapiResources;
  resource = selectContentImage(resource, data?.strapiResources);

  const carouselStyle = otherResources?.length === 1 && 'single-card-wrapper';

  const metaImage = resource?.metaImage
    ? resource?.metaImage?.localFile?.childImageSharp?.resize
    : null;

  const downloadURL = getAbsoluteURL(
    resource?.attachment?.publicURL,
    resource?.attachment?.url
  );

  const coverImageUrl = resource?.cover_image?.url
    ? `${process.env.GATSBY_API_URL}${resource?.cover_image?.url}`
    : null;

  const thumbnailImageUrl = resource?.thumbnail_image?.url
    ? `${process.env.GATSBY_API_URL}${resource?.thumbnail_image?.url}`
    : null;

  return (
    <Layout path={location?.pathname}>
      <SEO
        title={resource?.title}
        metaImage={metaImage}
        description="Resources"
      />
      <div className="resources-page-section _section-padding">
        <div className="resources-page">
          <div className="content-and-side-ads-container">
            <div className="content-column">
              <div className="image-container">
                <Image
                  className="desktop-image"
                  image={
                    resource?.cover_image?.localFile?.childImageSharp
                      ?.gatsbyImageData || coverImageUrl
                  }
                />
                <Image
                  className="phone-image"
                  image={
                    resource?.thumbnail_image?.localFile?.childImageSharp
                      ?.gatsbyImageData || thumbnailImageUrl
                  }
                />
              </div>
              <div className="header-wrapper">
                <h1 className="resources-title _section-label">
                  {resource?.title}
                </h1>
                <div className="author-info">
                  <div className="author">
                    by{' '}
                    <span className="author-label">
                      {resource?.created_by?.username}
                    </span>
                  </div>
                </div>
              </div>

              <MarkdownViewer
                className={'article-view'}
                source={resource?.description}
              />

              <div className="file-info-container">
                <SpriteIconViewer
                  className="icon-wrapper"
                  spriteId="file-icon"
                />

                <div className="file-name-wrapper">
                  <div className="file-text">
                    {resource?.attachment?.extension}
                  </div>
                  <div className="label">
                    {formatBytes(resource?.attachment?.size)}
                  </div>
                </div>
              </div>

              <div>
                <a download={resource?.title} href={downloadURL}>
                  <Button type="primary" className="primary-btn download">
                    Download for free
                  </Button>
                </a>
              </div>

              <SocialSharing
                path={location?.pathname}
                title={resource?.title}
              />
            </div>

            <DesktopAds />
          </div>

          <AuthorPagePreview
            tagLine={previewAuthor?.company_tagline}
            authorName={
              previewAuthor?.company_name || resource?.created_by?.username
            }
            logo={
              previewAuthor?.avatar?.localFile?.childImageSharp?.gatsbyImageData
            }
            profileLink={
              previewAuthor?.slug ? `/${previewAuthor?.slug}` : '/about'
            }
          />

          <MobileAds />

          {!isEmpty(otherResources) ? (
            <div className="other-resources-container">
              <CardCarousel
                title="Other resources"
                items={otherResources}
                className={carouselStyle}
                linkRoot="/career-development/resources/"
              />
            </div>
          ) : (
            <div className="bottom-space" />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Resources;

export const query = graphql`
  query ResourcesQuery($id: Int!, $title: String, $authorId: Int) {
    strapiResources(strapiId: { eq: $id }) {
      strapiId
      title
      description
      metaImage: cover {
        localFile {
          childImageSharp {
            resize(width: 1200, height: 628, quality: 100) {
              src
              width
              height
            }
          }
        }
        url
      }

      cover_image {
        source_image {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100)
            }
          }
          url
        }
        cropped_image {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100)
            }
          }
          url
        }
      }
      cover_image_old: cover {
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, quality: 100)
          }
        }
        url
      }

      thumbnail_image {
        source_image {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100)
            }
          }
          url
        }
        cropped_image {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100)
            }
          }
          url
        }
      }
      thumbnail_image_old: thumbnail {
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, quality: 100)
          }
        }
        url
      }

      creator_id
      created_by {
        username
      }

      attachment {
        size
        url
        publicURL
        extension: ext
      }
    }
    allStrapiResources(
      limit: 6
      sort: { fields: published_date, order: DESC }
      filter: { title: { ne: $title } }
    ) {
      nodes {
        strapiId
        slug
        title
        author: created_by {
          username
        }

        thumbnail_image {
          source_image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100)
              }
            }
            url
          }
          cropped_image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100)
              }
            }
            url
          }
        }
        thumbnail_image_old: thumbnail {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100)
            }
          }
          url
        }
      }
    }
    strapiAuthorPages(created_by: { id: { eq: $authorId } }) {
      avatar {
        localFile {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED

              quality: 100
              transformOptions: { cropFocus: CENTER }
              layout: FULL_WIDTH
              aspectRatio: 1
            )
          }
        }
        url
      }
      company_name
      company_tagline
      slug
    }
  }
`;
